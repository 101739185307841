import cx from 'classnames';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ActiveIndicator } from '../../../common/active-indicator/active-indicator.component';
import { Button } from '../../../common/button/button.component';
import { Collapse } from '../../../common/collapse/collapse.component';

import styles from './subscription-option.module.scss';

interface ISubscriptionOptionOuterProps {
    price: string;
    isActive: boolean; // Represent an active option
    isPending?: boolean; // Represent an option which needs updates before becoming active or inactive.
    pendingCause?: string; // Used to explain the pending state to the user (used in remote-assistance).
    expanded: boolean;
    onExpand: (...args: any[]) => any;
    className?: string;
    onActivated: (...args: any[]) => any;
    onConfigure?: (...args: any[]) => any;
    onDeactivated: (...args: any[]) => any;
}

interface ISubscriptionOptionProps extends ISubscriptionOptionOuterProps {
    name: string;
    content: ReactNode;
}

const SubscriptionOption = ({
    name,
    price,
    isActive,
    isPending,
    expanded,
    onExpand,
    content,
    onActivated,
    onConfigure,
    onDeactivated,
    className,
}: ISubscriptionOptionProps) => {
    const { t } = useTranslation('subOptions');

    const renderPanel = () => {
        return (
            <div className={styles.panel}>
                <span className={styles['option-name']}>{name}</span>
                <ActiveIndicator
                    className={styles['option-status']}
                    active={isActive}
                    pending={isPending || false} // Pending is false by default (as not required for every options)
                />

                <span className={styles['option-price']}>
                    <>{price}  / {t('periods:BASE.MONTH')}</>
                </span>

            </div>
        );
    };

    const renderContent = () => {
        let button;
        let configureButton;

        if (isActive || isPending) {
            button = (
                <Button
                    onClick={onDeactivated}
                    className={styles['action-button']}
                    small
                    secondary
                >
                    <> {t('commonActions:CANCEL')}</>
                </Button>
            );
        } else {
            button = (
                <Button
                    onClick={onActivated}
                    className={styles['action-button']}
                    small
                    primary
                >
                    <>{t('commonActions:ACTIVATE')}</>
                </Button>
            );
        }

        if (onConfigure) {
            configureButton = (
                <Button
                    onClick={onConfigure}
                    className={styles['action-button']}
                    small
                    secondary
                >
                    <>
                        {t('commonActions:CONFIGURE')}
                    </>
                </Button>
            );
        }
        return (
            <div data-testid="subscription-option-content">
                {content}
                {button}
                {configureButton}
            </div>
        );
    };

    return (
        <Collapse
            className={cx(className)}
            panel={renderPanel()}
            content={renderContent()}
            expanded={expanded}
            onExpand={onExpand}
        />
    );
};

export const WarrantyOption = ({
    price,
    isActive,
    expanded,
    onExpand,
    className,
    onActivated,
    onDeactivated,
}: ISubscriptionOptionOuterProps) => {
    const { t } = useTranslation('subOptions');

    let features = t('WARRANTY.FEATURES', {
        returnObjects: true,
    }) as string[];

    /**
     * Required for tests
     */
    if (typeof features === 'string') {
        features = [features];
    }

    const featuresArray: string[] = [];
    for (const value of Object.values(features)) {
        featuresArray.push(value);
    }

    const renderContent = () => (
        <ul className={styles['features-list']}>
            {featuresArray.map((f, idx) => (
                <li key={idx}>{f}</li>
            ))}
        </ul>
    );
    return (
        <SubscriptionOption
            onActivated={onActivated}
            onDeactivated={onDeactivated}
            className={className}
            name={t('WARRANTY.BASE')}
            price={price}
            isActive={isActive}
            content={renderContent()}
            expanded={expanded}
            onExpand={onExpand}
        />
    );
};

export const PremiumPackOption = ({
    className,
    expanded,
    isActive,
    price,
    onActivated,
    onDeactivated,
    onExpand,
}: ISubscriptionOptionOuterProps) => {
    const { t } = useTranslation('subOptions');

    let features = [
        {
            title: t('SMS_PACK.BASE'),
            content: t('PREMIUM.FEATURE.SMS.TEXT', {
                returnObjects: true,
            }) as string[],
        },
        {
            title: t('MAP.TITLE'),
            content: t('MAP.FEATURES', {
                returnObjects: true,
            }) as string[],
        },
    ];

    /**
     * Required for tests
     */
    if (typeof features === 'string') {
        features = [features];
    }

    const renderContent = () => (
        <>
            {t('PREMIUM.SUBTITLE')}
            <ul className={styles['features-list']}>
                {features.map((f, idx) => (
                    <li key={idx}>
                        <p className={styles.title}>{f.title}</p>
                        {f.content}
                    </li>
                ))}
            </ul>
        </>
    );

    return (
        <SubscriptionOption
            onActivated={onActivated}
            onDeactivated={onDeactivated}
            className={className}
            name={t('PREMIUM.BASE')}
            price={price}
            isActive={isActive}
            content={renderContent()}
            expanded={expanded}
            onExpand={onExpand}
        />
    );
};
