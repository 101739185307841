import cx from 'classnames';
import React, { HTMLAttributes, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CallCenterIcon } from '../../../images/call-center.svg';
import { ReactComponent as PremiumMapIcon } from '../../../images/premium-map-icon.svg';
import { ReactComponent as PremiumIcon } from '../../../images/premium-pack-icon.svg';
import { ReactComponent as SmsIcon } from '../../../images/sms-premium-icon.svg';
import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import { Button } from '../button/button.component';
import styles from './popup-premium.module.scss';

const getModalRoot = () => document.getElementById('modals');

export interface IModalProps extends HTMLAttributes<HTMLDivElement> {
    onClosed: () => void;
    onClick: () => void;
    price: string | undefined;
}

export const PremiumPopup = ({
    children,
    price,
    onClick,
    className,
    onClosed,
    ...props
}: IModalProps) => {
    const { t } = useTranslation('subOptions');

    const containerRef = useRef<HTMLElement | null>(null);
    const getContainer = () => {
        if (!containerRef.current) {
            containerRef.current = document.createElement('div');
            containerRef.current.setAttribute('data-testid', 'modal-container');
        }

        return containerRef.current;
    };
    const mayRenderTextContainer = (title: string, text: string, icon: any) => {
        return (
            <div className={styles.optionFeatureBlock}>
                <div className={styles.optionFeatureIcon}>{icon}</div>
                <div className={styles.optionFeatureTextBlock}>
                    <div className={styles.optionFeatureTitle}>{title}</div>
                    <div className={styles.optionFeatureText}>{text}</div>
                </div>
            </div>
        );
    };
    const modalRoot = getModalRoot();
    modalRoot?.appendChild(getContainer());

    const renderModal = () => {
        const maybeRenderCloseButton = () => {
            if (onClosed) {
                return (
                    <button onClick={onClosed} className={styles.closeButton}>
                        <CrossIcon />
                    </button>
                );
            }
        };
        return (
            <div
                className={styles.overlay}
                onClick={onClosed}
                data-testid="popup-overlay"
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={cx(styles.modal, className)}
                    {...props}
                >
                    {children}
                    <div className={styles.container}>
                        {maybeRenderCloseButton()}
                        <div className={styles.header}>
                            {t('PREMIUM.TITLE_POPUP')}
                        </div>
                        <div className={styles.icon}>
                            <PremiumIcon className={styles.svg} />
                        </div>
                        <div className={styles.mainContent}>
                            <div className={styles.mainContentContainer}>
                                <div className={styles.mainContentTitle}>
                                    {t('PREMIUM.TITLE_DISCOVER')}
                                </div>
                                <div className={styles.mainContent}>
                                    {mayRenderTextContainer(
                                        t('SMS_PACK.BASE'),
                                        t('PREMIUM.FEATURE.SMS.TEXT'),
                                        <SmsIcon />,
                                    )}
                                    {mayRenderTextContainer(
                                        t('MAP.BASE'),
                                        t('MAP.FEATURES'),
                                        <PremiumMapIcon />,
                                    )}
                                </div>

                                <div className={styles.mainContent}>
                                    <Button
                                        className={styles.button}
                                        onClick={onClick}
                                    >
                                        {t('PREMIUM.BUTTON.FREEMONTH')}
                                    </Button>
                                </div>
                                <div className={styles.textContainer}>
                                    <p>
                                        {t('PREMIUM.TEXT_INFO_PRICE', {
                                            price,
                                        })}
                                    </p>
                                </div>
                                <div className={styles.subTextContainer}>
                                    <p>{t('PREMIUM.SUB_LEGAL')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return createPortal(renderModal(), getContainer());
};
