import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { getGlobalT } from '../../../../i18n/setup-translations';
import { ReactComponent as IconGraphBar } from '../../../../images/activityTracking/chart-bar-menu-icon.svg';
import { MenuLink } from '../../../common/menu/menu-link.component';
import { ReactComponent as GeofenceIcon } from '../../../icons/geofence-grey.svg';
import { ReactComponent as HistoryIcon } from '../../../icons/history.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settings.svg';
import { ReactComponent as SubscriptionIcon } from '../../../icons/subscrption.svg';
import { ReactComponent as TransferIcon } from '../../../icons/transfer.svg';
import { ReactComponent as WifiIcon } from '../../../icons/wifi-icon.svg';
import { ReactComponent as BetaSvg } from '../../../../images/ACTIVITY_TRACKING/beta.svg';

import styles from './trackers-links-menu.module.scss';

type availableLinks =
    | 'subscription'
    | 'geofences'
    | 'wifi-zones'
    | 'settings'
    | 'history';
export interface IOuterProps {
    hasNoWifiZones?: boolean;
    isAnimalType: boolean;
}
export interface ITrackersLinksMenuProps
    extends HTMLAttributes<HTMLUListElement>,
    IOuterProps {
    activeLink: availableLinks | null;
    onSubscriptionClick(): void;
    onGeofencesClick(): void;
    onWifiZonesClick(): void;
    onSettingsClick(): void;
    onHistoryClick(): void;
    onTransferClick(): void;
}

export const TrackersLinksMenu = ({
    hasNoWifiZones,
    isAnimalType,
    onSubscriptionClick,
    onGeofencesClick,
    onSettingsClick,
    onHistoryClick,
    onWifiZonesClick,
    activeLink,
    className,
    onTransferClick,
}: ITrackersLinksMenuProps) => {
    const t = getGlobalT();
    const maybeRenderWifiZoneLink = (
        hasNoCurrentWifiZones: boolean | undefined,
    ) => {
        if (hasNoCurrentWifiZones === true) {
            return;
        }
        return (
            <div
                role="button"
                className={cx(styles.link, {
                    [styles['active-link']]: activeLink === 'wifi-zones',
                })}
                onClick={onWifiZonesClick}
            >
                <MenuLink icon={<WifiIcon />}>{t('wifiZones:TITLE')}</MenuLink>
            </div>
        );
    };

    return (
        <ul className={cx(styles.list, className)}>
            <li>
                <div
                    role="button"
                    className={cx(styles.link, {
                        [styles['active-link']]: activeLink === 'history',
                    })}
                    onClick={onHistoryClick}
                >
                    <MenuLink icon={<HistoryIcon />}>
                        {t('trackers:LINKS.HISTORY')}
                    </MenuLink>
                </div>
                <div
                    role="button"
                    className={cx(styles.link, {
                        [styles['active-link']]: activeLink === 'geofences',
                    })}
                    onClick={onGeofencesClick}
                >
                    <MenuLink icon={<GeofenceIcon />}>
                        {t('trackers:LINKS.GEOFENCES')}
                    </MenuLink>
                </div>
                {maybeRenderWifiZoneLink(hasNoWifiZones)}
                <div
                    role="button"
                    className={cx(styles.link, {
                        [styles['active-link']]: activeLink === 'settings',
                    })}
                    onClick={onSettingsClick}
                >
                    <MenuLink icon={<SettingsIcon />}>
                        {t('trackers:LINKS.SETTINGS')}
                    </MenuLink>
                </div>
                <div
                    role="button"
                    className={cx(styles.link, {
                        [styles['active-link']]: activeLink === 'subscription',
                    })}
                    onClick={onSubscriptionClick}
                >
                    <MenuLink icon={<SubscriptionIcon />}>
                        {t('trackers:LINKS.SUBSCRIPTION')}
                    </MenuLink>
                </div>
                <div
                    role="button"
                    className={cx(styles.link)}
                    onClick={onTransferClick}
                >
                    <MenuLink icon={<TransferIcon />}>
                        {t(
                            'subscriptions:OVERVIEW.REPLACE_TRACKER_BUTTON.LABEL',
                        )}
                    </MenuLink>
                </div>
            </li>
        </ul>
    );
};

/**
 * TODO: Rewrite to more DRY
 */

export interface IWithRouterOuterProps {
    hasNoWifiZones?: boolean;
    isAnimalType: boolean;
    trackerId: number;
}
export interface ITrackerLinksWithRouterProps
    extends IWithRouterOuterProps { }

const WithRouter = ({
    isAnimalType,
    hasNoWifiZones,
    trackerId,
}: ITrackerLinksWithRouterProps) => {
    const history = useNavigate()
    const { id } = useParams()
    let currentTrackerId: string | undefined = id
    if (!currentTrackerId) {
        currentTrackerId = trackerId.toString();
    }
    const url = useLocation()
    const goToSubscription = () => {
        history(`/trackers/${currentTrackerId}/subscription`);
    };

    const goToGeofences = () => {
        history(`/trackers/${currentTrackerId}/geofences`);
    };

    const goToWifiZones = () => {
        history(`/trackers/${currentTrackerId}/wifi-zones`);
    };

    const goToSettings = () => {
        history(`/trackers/${currentTrackerId}/settings`);
    };

    const goToHistory = () => {
        history(`/trackers/${currentTrackerId}/history`);
    };

    const goToTransfer = () => {
        history('/transfer');
    };


    const getActiveLink = (): availableLinks | null => {
        if (url.pathname.includes('subscription')) {
            return 'subscription';
        }
        if (url.pathname.includes('geofences')) {
            return 'geofences';
        }
        if (url.pathname.includes('wifi-zones')) {
            return 'wifi-zones';
        }
        if (url.pathname.includes('settings')) {
            return 'settings';
        }
        if (url.pathname.includes('history')) {
            return 'history';
        }

        return null;
    };

    return (
        <TrackersLinksMenu
            hasNoWifiZones={hasNoWifiZones}
            isAnimalType={isAnimalType}
            onSettingsClick={goToSettings}
            onSubscriptionClick={goToSubscription}
            onGeofencesClick={goToGeofences}
            onWifiZonesClick={goToWifiZones}
            onHistoryClick={goToHistory}
            onTransferClick={goToTransfer}
            activeLink={getActiveLink()}
        />
    );
};

export const TrackersLinksMenuWithRouter = WithRouter;
